@import '../../scss/settings.scss';

.Graph {
  position: relative;
  margin: 2.5rem 0 auto 1rem;
  width: 60vw;
  height: 90vh;
  min-height: 20rem;
  border: 1px solid var(--theme);
  border-radius: 0.25rem;

  &-Container {
    display: flex;
    gap: 3rem;

    button {
      font-size: 0.8rem;
      background-color: #cfb37d;
      padding: 0.5rem 0.75rem;
      border-radius: 0.5rem;
      width: fit-content;
      margin: auto 0 auto auto;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-JsonButtons {
    padding-bottom: 2rem;
    border-bottom: 1px solid #333;
    display: flex;
    gap: 1rem;
    justify-content: center;

    button {
      margin: 0;
      background-color: #a3c19b;
    }
  }

  #my-network {
    width: 100%;
    height: 100%;
  }

  &-FigureButtons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 25rem;
    margin: 2rem auto 3rem;

    button {
      margin: auto;
    }
  }

  &-Controls {
    margin: 5rem 1rem auto 0;
    width: 25rem;
  }

  &-GroupEdit {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border: 1px solid #333;
    padding: 1rem;
    margin: 1rem 0;

    &-Title {
      font-weight: 600;
      font-size: 1rem;
    }
  }

  &-RowEdit {
    font-size: 0.8rem;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }

  &-RowValue {
    width: 12rem;
    display: flex;
    gap: 1rem;
  }

  &-SliderValue {
    width: 1.5rem;
  }
}

.InputUpload {
  display: none;
}

.Switch-Container {
  position: relative;
  display: inline-block;
  width: 3.1rem;
  height: 1.4rem;
}

.Switch-Container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Switch-Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Switch-Slider:before {
  position: absolute;
  content: '';
  height: 1rem;
  width: 1rem;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .Switch-Slider {
  background-color: #2196f3;
}

input:focus + .Switch-Slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .Switch-Slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.Switch-Slider.Switch-Round {
  border-radius: 1.2rem;
}

.Switch-Slider.Switch-Round:before {
  border-radius: 50%;
}
